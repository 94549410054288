@import "fonts";

body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: #004a96;
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
}

a:hover {
  text-decoration: underline;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media (hover: none) {
  .MuiButton-containedSecondary:hover,
  .MuiButton-outlinedPrimary:hover {
    background-color: white;
    color: #004a96;
  }
}
