@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=61130b59-32f1-4017-901d-9202a0cdd42e);
@font-face {
  font-family: "Helvetica Neue LT W05_25 Ult Lt";
  src: url(/static/media/ec6281a0-c9c4-4477-a360-156acd53093f.d156921b.woff2) format("woff2"),
    url(/static/media/11066b40-10f7-4123-ba58-d9cbf5e89ceb.056bf839.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_26UltLtIt";
  src: url(/static/media/2707a251-2d32-4bb6-a3c4-87114ba2365f.b214f727.woff2) format("woff2"),
    url(/static/media/40f50724-486b-4e7b-9366-237e06eabfc8.54b9ca85.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_35 Thin";
  src: url(/static/media/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.f136bae6.woff2) format("woff2"),
    url(/static/media/b2c1327f-ab3d-4230-93d7-eee8596e1498.85d2df8d.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_36 Th It";
  src: url(/static/media/2a7e8f89-c0b2-4334-9c34-7a2078d2b959.f1bc62dc.woff2) format("woff2"),
    url(/static/media/32aad9d8-5fec-4b9d-ad53-4cf7a5b53698.858ac2ce.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_45 Light";
  src: url(/static/media/f9c5199e-a996-4c08-9042-1eb845bb7495.3bdf58ba.woff2) format("woff2"),
    url(/static/media/2a34f1f8-d701-4949-b12d-133c1c2636eb.e606dd04.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_46 Lt It";
  src: url(/static/media/5e4f385b-17ff-4d27-a63a-9ee28546c9a8.1270d201.woff2) format("woff2"),
    url(/static/media/116cde47-4a07-44a5-9fac-cbdcc1f14f79.132f8bee.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url(/static/media/08b57253-2e0d-4c12-9c57-107f6c67bc49.9f91cf0e.woff2) format("woff2"),
    url(/static/media/08edde9d-c27b-4731-a27f-d6cd9b01cd06.ab52e8bb.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_56 Italic";
  src: url(/static/media/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.05ee2ea9.woff2) format("woff2"),
    url(/static/media/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.b24196de.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_65 Medium";
  src: url(/static/media/240c57a0-fdce-440d-9ce3-85e0cb56f470.d5725ea7.woff2) format("woff2"),
    url(/static/media/7802e576-2ffa-4f22-a409-534355fbea79.b47da42c.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_66 Md It";
  src: url(/static/media/de68be2a-5d0e-4b8d-b3eb-940f75503e2a.a806eac7.woff2) format("woff2"),
    url(/static/media/31029e78-79a0-4940-b82d-2e3c238e1355.6254fae1.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_75 Bold";
  src: url(/static/media/800da3b0-675f-465f-892d-d76cecbdd5b1.bd9703ed.woff2) format("woff2"),
    url(/static/media/7b415a05-784a-4a4c-8c94-67e9288312f5.0a5249b3.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_76 Bd It";
  src: url(/static/media/13ab58b4-b5ba-4c95-afde-ab2608fbbbd9.7b128339.woff2) format("woff2"),
    url(/static/media/5018b5b5-c821-4653-bc74-d0b11d735f1a.bb778673.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_85 Heavy";
  src: url(/static/media/7e42a406-9133-48c0-a705-4264ac520b43.69802e8a.woff2) format("woff2"),
    url(/static/media/837750e9-3227-455d-a04e-dc76764aefcf.0baeb025.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_86 Hv It";
  src: url(/static/media/0acba88f-0de4-4d43-81fd-920d7427f665.14dce2c5.woff2) format("woff2"),
    url(/static/media/713c9c40-9cbd-4276-819e-d0efaf5d3923.21f14d4d.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_95 Black";
  src: url(/static/media/fc4fb6ca-f981-4115-b882-c78e9f08be52.9308139d.woff2) format("woff2"),
    url(/static/media/6ed03453-f512-45ba-84bf-fe4ea45d5e6a.555dcad6.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_96 Blk It";
  src: url(/static/media/995add04-59cc-41ea-abd2-4712eaddf2a8.c42c36ba.woff2) format("woff2"),
    url(/static/media/7090e465-f6bf-4664-8b5a-d877a6915d87.eed7b643.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_75 Bd Ou";
  src: url(/static/media/933a0af8-7461-40c7-acc4-9fc9a70aca3a.fe30b5af.woff2) format("woff2"),
    url(/static/media/6249015c-3be5-4b2d-822b-f43bf7f278f4.acc98335.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_27UltLtC";
  src: url(/static/media/fb306239-7f59-4f44-b617-23c761ba3cb1.8db5cd31.woff2) format("woff2"),
    url(/static/media/a3af510f-3434-46ba-873d-bc503a1a8b4e.70dc04d7.woff) format("woff");
}
@font-face {
  font-family: "Helvetica NeueLTW0527UltLtCnOb";
  src: url(/static/media/cd911115-5657-4542-86e9-b5aa4cbcced7.5111d3b0.woff2) format("woff2"),
    url(/static/media/2316696f-3b84-4d49-b666-bf2d97f338e6.78b634e9.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_37 Th Cn";
  src: url(/static/media/9d1ff4e9-ed81-4251-88c5-c9c1352a215b.8bb1cec2.woff2) format("woff2"),
    url(/static/media/97135348-a627-408f-8626-ad4f413621b7.b01b23db.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_37ThCnOb";
  src: url(/static/media/cec55e42-b740-41ea-bb12-f45324e59638.4b261298.woff2) format("woff2"),
    url(/static/media/5e3b3ff0-2fac-45a2-9e6c-b8c2e8d6705a.75f810d8.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_47 Lt Cn";
  src: url(/static/media/083e1179-98b4-4cbd-a13a-50a7c78d6fea.5d2e069d.woff2) format("woff2"),
    url(/static/media/239170e6-7f0d-4c35-97d4-490b362e4ef7.b54410f1.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_47LtCnOb";
  src: url(/static/media/4e0d849a-b425-400e-a40c-789c4a37aac0.3a42b427.woff2) format("woff2"),
    url(/static/media/71ce5b5d-325e-417b-843b-e8f6e554a182.4698bad1.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_57 Cond";
  src: url(/static/media/9516ef74-4fe9-45fe-a09f-493cb9518f71.0c660276.woff2) format("woff2"),
    url(/static/media/5b99bc59-1b2e-446b-961e-2e1dbdfcd641.9bfeeddb.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_57 Cn Obl";
  src: url(/static/media/955e2e4b-4004-4325-a6a1-80acc54ff5c0.b5b0ddc1.woff2) format("woff2"),
    url(/static/media/f6047d08-65e9-4b62-8147-b118b85d8deb.659c06b4.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_67 Md Cn";
  src: url(/static/media/1a559dd9-e51e-45d5-86eb-f343223bacef.c5487534.woff2) format("woff2"),
    url(/static/media/581fae27-b5f4-44ab-b5b1-92f11d4f9838.534a7c95.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_67MdCnObl";
  src: url(/static/media/9c31450f-5d89-4ebd-afc6-9962b19448f5.f51ed947.woff2) format("woff2"),
    url(/static/media/d0d30f97-b639-4228-8b85-fe2f879968db.96c18878.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_77 Bd Cn";
  src: url(/static/media/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.098be013.woff2) format("woff2"),
    url(/static/media/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.fd7a1ff6.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_77BdCnOb";
  src: url(/static/media/83988233-0e17-4c53-b35a-2f1c0c2a3ca1.fa0cf536.woff2) format("woff2"),
    url(/static/media/22f5df0d-ec99-4f2e-a776-ca2bdf0df48a.0d1af3fe.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_87 Hv Cn";
  src: url(/static/media/28343101-8a93-4048-85b0-ef49feebe7bf.463bec4a.woff2) format("woff2"),
    url(/static/media/799e7d1f-b263-4621-9776-df01ebcff3e2.71a308f4.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_87HvCnObl";
  src: url(/static/media/9cc465b5-d49c-435c-86f2-52b4724e4e13.79755640.woff2) format("woff2"),
    url(/static/media/9b01165b-5359-4cf6-8222-e6b0dc4dfa54.b31a7c5b.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_97 Blk C";
  src: url(/static/media/f2f49a5e-567d-4dfa-8152-c28c8f60c84e.b052babf.woff2) format("woff2"),
    url(/static/media/ea7ce364-9de4-43c3-9b0b-7b26af453629.554e9bbf.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W0597BlkCnObl";
  src: url(/static/media/635ef666-139c-4f6c-a671-648acbcd5619.6226594c.woff2) format("woff2"),
    url(/static/media/e4933688-4bbe-4e73-9faf-13865c4eed39.aedc9226.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_107XBlkC";
  src: url(/static/media/91164c5b-326b-430d-a6bf-9415d6b57c42.b9ae49ad.woff2) format("woff2"),
    url(/static/media/7de96184-e0a5-4413-8dc0-5c15619cef19.7c862236.woff) format("woff");
}
@font-face {
  font-family: "Helvetica NeueLTW05107XBlkCnOb";
  src: url(/static/media/932d2296-d705-410a-8e4c-d68f32085933.a8dae1d7.woff2) format("woff2"),
    url(/static/media/06b24b76-8a44-4b13-81cf-01630e8de97d.44a16dd8.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_29 Cm Ult L";
  src: url(/static/media/9c4783fd-4d57-415c-83f8-fbcd4632290b.21a23083.woff2) format("woff2"),
    url(/static/media/9b248368-d8c2-4278-8524-f14ac5bbf7c2.12874e12.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_39 Comp Thi";
  src: url(/static/media/642a878f-7553-4f21-a375-7f431f169eeb.67ff3518.woff2) format("woff2"),
    url(/static/media/ed2003ed-8181-4c1c-9cb4-cc83ffe7aecb.cb1a3962.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_49 Cm Light";
  src: url(/static/media/b08de6fb-48b1-4f21-a5e2-9f06002c65de.d1fcfc0f.woff2) format("woff2"),
    url(/static/media/9350baa2-4e36-4a96-9418-f9920966ab64.61e89629.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_59 Comp";
  src: url(/static/media/1e4b9000-6517-4aa6-aa50-3ea95a7f64ae.16399fd0.woff2) format("woff2"),
    url(/static/media/64abcd55-d012-4bb5-a09d-a1e448f2b4b0.c88df2ad.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_69 Cm Mediu";
  src: url(/static/media/d630513e-94d0-4fed-9497-bc762577c07f.8671914c.woff2) format("woff2"),
    url(/static/media/b9351fa0-4d79-45f7-9236-28323476e99f.e5dab162.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_79 Comp Bol";
  src: url(/static/media/58583898-0494-4579-bafb-d504aa8cc480.55435a6f.woff2) format("woff2"),
    url(/static/media/b2053850-e2c0-44fa-a8af-8b299aa6fdb0.1e62820d.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_89 Cm Heavy";
  src: url(/static/media/37affb68-d2dd-460e-8248-b699baced865.a54a424f.woff2) format("woff2"),
    url(/static/media/675d0ef2-6dea-42ca-80fd-175b687eac14.af8d35e7.woff) format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_99 Cm Black";
  src: url(/static/media/01b2cbed-29f5-498d-ad87-e567fe871fc1.21639947.woff2) format("woff2"),
    url(/static/media/708ab8e2-2120-4b70-9c16-0fc203f1ec9c.61a45452.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_23UltLtE";
  src: url(/static/media/7efec9b7-1374-4adc-8d45-514361fd48b8.ac2b4b24.woff2) format("woff2"),
    url(/static/media/06b4e4fc-e732-41a7-a362-be9f88aadc89.6ee9aef5.woff) format("woff");
}
@font-face {
  font-family: "Helvetica NeueLTW0523UltLtExObl";
  src: url(/static/media/7494fa52-ef60-4526-ae97-6a79476f65d1.93338f6e.woff2) format("woff2"),
    url(/static/media/a97edc7d-e8eb-4e13-bccf-2762d3edad44.ee5e61b6.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_33 Th Ex";
  src: url(/static/media/2f270d4e-e43c-457e-ba8a-0dd33bea037a.a03dd4d3.woff2) format("woff2"),
    url(/static/media/4595f8b8-db8c-4c01-a7a4-9bb031810849.288751e0.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_33ThExObl";
  src: url(/static/media/cdf91fc1-04f6-4402-b94c-e943abfa7dea.85206dd8.woff2) format("woff2"),
    url(/static/media/36b66102-f03a-4210-bbee-1c610abba3e3.ac9e237c.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_43 Lt Ex";
  src: url(/static/media/86578ae8-4abb-42d0-8784-375d07db941f.904d759f.woff2) format("woff2"),
    url(/static/media/18b6ae6b-f0cf-422b-bf3f-35be98be3c19.a56e7f9d.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_43LtExOb";
  src: url(/static/media/c12fc731-15eb-44eb-94a1-fe233d5665b9.443f65ed.woff2) format("woff2"),
    url(/static/media/fde07ba4-9a82-44e0-8f27-969ec84addaa.312b9df2.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_53 Ext";
  src: url(/static/media/0504481e-774c-4e57-a055-c94bb5303380.3b7fb39d.woff2) format("woff2"),
    url(/static/media/bf166391-2bf9-47fd-8382-193fffb422c4.769bf1d0.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_53 Ex Ob";
  src: url(/static/media/d829f114-8f21-4a35-a303-5fc44e646b98.785ee0d8.woff2) format("woff2"),
    url(/static/media/12be0a3b-503f-4ef3-a94b-0fcce636cd9c.c603afd9.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_63 Md Ex";
  src: url(/static/media/5069b541-02d6-4f13-9ec2-07e07e9d683f.3b930231.woff2) format("woff2"),
    url(/static/media/cef4a236-7e60-42cd-997c-99487ff6bb0a.0803b2cb.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_63MdExOb";
  src: url(/static/media/bdac6a25-6e2e-444a-bf57-324e1c75aebe.41270dca.woff2) format("woff2"),
    url(/static/media/c92fa14b-1125-4b35-af82-e201823dd789.ca204ad1.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_73 Bd Ex";
  src: url(/static/media/3e37e0b1-8062-42c6-8664-4ea78aa2de25.dfd68a46.woff2) format("woff2"),
    url(/static/media/a25e22f2-80cf-4f21-a365-b77c87a3edf8.1050f7e5.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_73BdExObl";
  src: url(/static/media/a9a0e1ca-8be8-4441-8165-dfca8a447fe9.1be2db72.woff2) format("woff2"),
    url(/static/media/23ffc3f6-e1a7-4b01-b5a8-af27dcd7921c.46cddff9.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_83 Hv Ex";
  src: url(/static/media/37cade8a-4499-42b4-b79f-99143dd3a764.cd5b9701.woff2) format("woff2"),
    url(/static/media/6823dde3-d344-4bb4-b5a4-add12527140d.6a295ac6.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_83HvExObl";
  src: url(/static/media/1250e9a0-b13e-46b1-9c3f-60e487e7c715.acb9261c.woff2) format("woff2"),
    url(/static/media/9eee9642-ade0-42ba-8906-a13a54c2422a.fc016e71.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_93 Blk E";
  src: url(/static/media/275e1e1e-08b1-4fe8-952b-5484d602fa4e.76881a3f.woff2) format("woff2"),
    url(/static/media/4996b52c-3eb3-43f9-b701-452d8179a1de.032e4e54.woff) format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W0593BlkExObl";
  src: url(/static/media/6f623488-6c71-4b67-be62-9a2b09c2f56a.273bd279.woff2) format("woff2"),
    url(/static/media/6527f829-2c75-49cb-b90f-a7c3f19366f6.2cf42786.woff) format("woff");
}

body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: #004a96;
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
}

a:hover {
  text-decoration: underline;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media (hover: none) {
  .MuiButton-containedSecondary:hover,
  .MuiButton-outlinedPrimary:hover {
    background-color: white;
    color: #004a96;
  }
}

