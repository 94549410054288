@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=61130b59-32f1-4017-901d-9202a0cdd42e);

@font-face {
  font-family: "Helvetica Neue LT W05_25 Ult Lt";
  src: url("Fonts/ec6281a0-c9c4-4477-a360-156acd53093f.woff2") format("woff2"),
    url("Fonts/11066b40-10f7-4123-ba58-d9cbf5e89ceb.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_26UltLtIt";
  src: url("Fonts/2707a251-2d32-4bb6-a3c4-87114ba2365f.woff2") format("woff2"),
    url("Fonts/40f50724-486b-4e7b-9366-237e06eabfc8.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_35 Thin";
  src: url("Fonts/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.woff2") format("woff2"),
    url("Fonts/b2c1327f-ab3d-4230-93d7-eee8596e1498.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_36 Th It";
  src: url("Fonts/2a7e8f89-c0b2-4334-9c34-7a2078d2b959.woff2") format("woff2"),
    url("Fonts/32aad9d8-5fec-4b9d-ad53-4cf7a5b53698.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_45 Light";
  src: url("Fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),
    url("Fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_46 Lt It";
  src: url("Fonts/5e4f385b-17ff-4d27-a63a-9ee28546c9a8.woff2") format("woff2"),
    url("Fonts/116cde47-4a07-44a5-9fac-cbdcc1f14f79.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url("Fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),
    url("Fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_56 Italic";
  src: url("Fonts/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2") format("woff2"),
    url("Fonts/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_65 Medium";
  src: url("Fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),
    url("Fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_66 Md It";
  src: url("Fonts/de68be2a-5d0e-4b8d-b3eb-940f75503e2a.woff2") format("woff2"),
    url("Fonts/31029e78-79a0-4940-b82d-2e3c238e1355.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_75 Bold";
  src: url("Fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"),
    url("Fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_76 Bd It";
  src: url("Fonts/13ab58b4-b5ba-4c95-afde-ab2608fbbbd9.woff2") format("woff2"),
    url("Fonts/5018b5b5-c821-4653-bc74-d0b11d735f1a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_85 Heavy";
  src: url("Fonts/7e42a406-9133-48c0-a705-4264ac520b43.woff2") format("woff2"),
    url("Fonts/837750e9-3227-455d-a04e-dc76764aefcf.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_86 Hv It";
  src: url("Fonts/0acba88f-0de4-4d43-81fd-920d7427f665.woff2") format("woff2"),
    url("Fonts/713c9c40-9cbd-4276-819e-d0efaf5d3923.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_95 Black";
  src: url("Fonts/fc4fb6ca-f981-4115-b882-c78e9f08be52.woff2") format("woff2"),
    url("Fonts/6ed03453-f512-45ba-84bf-fe4ea45d5e6a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_96 Blk It";
  src: url("Fonts/995add04-59cc-41ea-abd2-4712eaddf2a8.woff2") format("woff2"),
    url("Fonts/7090e465-f6bf-4664-8b5a-d877a6915d87.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_75 Bd Ou";
  src: url("Fonts/933a0af8-7461-40c7-acc4-9fc9a70aca3a.woff2") format("woff2"),
    url("Fonts/6249015c-3be5-4b2d-822b-f43bf7f278f4.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_27UltLtC";
  src: url("Fonts/fb306239-7f59-4f44-b617-23c761ba3cb1.woff2") format("woff2"),
    url("Fonts/a3af510f-3434-46ba-873d-bc503a1a8b4e.woff") format("woff");
}
@font-face {
  font-family: "Helvetica NeueLTW0527UltLtCnOb";
  src: url("Fonts/cd911115-5657-4542-86e9-b5aa4cbcced7.woff2") format("woff2"),
    url("Fonts/2316696f-3b84-4d49-b666-bf2d97f338e6.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_37 Th Cn";
  src: url("Fonts/9d1ff4e9-ed81-4251-88c5-c9c1352a215b.woff2") format("woff2"),
    url("Fonts/97135348-a627-408f-8626-ad4f413621b7.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_37ThCnOb";
  src: url("Fonts/cec55e42-b740-41ea-bb12-f45324e59638.woff2") format("woff2"),
    url("Fonts/5e3b3ff0-2fac-45a2-9e6c-b8c2e8d6705a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_47 Lt Cn";
  src: url("Fonts/083e1179-98b4-4cbd-a13a-50a7c78d6fea.woff2") format("woff2"),
    url("Fonts/239170e6-7f0d-4c35-97d4-490b362e4ef7.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_47LtCnOb";
  src: url("Fonts/4e0d849a-b425-400e-a40c-789c4a37aac0.woff2") format("woff2"),
    url("Fonts/71ce5b5d-325e-417b-843b-e8f6e554a182.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_57 Cond";
  src: url("Fonts/9516ef74-4fe9-45fe-a09f-493cb9518f71.woff2") format("woff2"),
    url("Fonts/5b99bc59-1b2e-446b-961e-2e1dbdfcd641.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_57 Cn Obl";
  src: url("Fonts/955e2e4b-4004-4325-a6a1-80acc54ff5c0.woff2") format("woff2"),
    url("Fonts/f6047d08-65e9-4b62-8147-b118b85d8deb.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_67 Md Cn";
  src: url("Fonts/1a559dd9-e51e-45d5-86eb-f343223bacef.woff2") format("woff2"),
    url("Fonts/581fae27-b5f4-44ab-b5b1-92f11d4f9838.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_67MdCnObl";
  src: url("Fonts/9c31450f-5d89-4ebd-afc6-9962b19448f5.woff2") format("woff2"),
    url("Fonts/d0d30f97-b639-4228-8b85-fe2f879968db.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_77 Bd Cn";
  src: url("Fonts/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"),
    url("Fonts/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_77BdCnOb";
  src: url("Fonts/83988233-0e17-4c53-b35a-2f1c0c2a3ca1.woff2") format("woff2"),
    url("Fonts/22f5df0d-ec99-4f2e-a776-ca2bdf0df48a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_87 Hv Cn";
  src: url("Fonts/28343101-8a93-4048-85b0-ef49feebe7bf.woff2") format("woff2"),
    url("Fonts/799e7d1f-b263-4621-9776-df01ebcff3e2.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_87HvCnObl";
  src: url("Fonts/9cc465b5-d49c-435c-86f2-52b4724e4e13.woff2") format("woff2"),
    url("Fonts/9b01165b-5359-4cf6-8222-e6b0dc4dfa54.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_97 Blk C";
  src: url("Fonts/f2f49a5e-567d-4dfa-8152-c28c8f60c84e.woff2") format("woff2"),
    url("Fonts/ea7ce364-9de4-43c3-9b0b-7b26af453629.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W0597BlkCnObl";
  src: url("Fonts/635ef666-139c-4f6c-a671-648acbcd5619.woff2") format("woff2"),
    url("Fonts/e4933688-4bbe-4e73-9faf-13865c4eed39.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_107XBlkC";
  src: url("Fonts/91164c5b-326b-430d-a6bf-9415d6b57c42.woff2") format("woff2"),
    url("Fonts/7de96184-e0a5-4413-8dc0-5c15619cef19.woff") format("woff");
}
@font-face {
  font-family: "Helvetica NeueLTW05107XBlkCnOb";
  src: url("Fonts/932d2296-d705-410a-8e4c-d68f32085933.woff2") format("woff2"),
    url("Fonts/06b24b76-8a44-4b13-81cf-01630e8de97d.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_29 Cm Ult L";
  src: url("Fonts/9c4783fd-4d57-415c-83f8-fbcd4632290b.woff2") format("woff2"),
    url("Fonts/9b248368-d8c2-4278-8524-f14ac5bbf7c2.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_39 Comp Thi";
  src: url("Fonts/642a878f-7553-4f21-a375-7f431f169eeb.woff2") format("woff2"),
    url("Fonts/ed2003ed-8181-4c1c-9cb4-cc83ffe7aecb.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_49 Cm Light";
  src: url("Fonts/b08de6fb-48b1-4f21-a5e2-9f06002c65de.woff2") format("woff2"),
    url("Fonts/9350baa2-4e36-4a96-9418-f9920966ab64.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_59 Comp";
  src: url("Fonts/1e4b9000-6517-4aa6-aa50-3ea95a7f64ae.woff2") format("woff2"),
    url("Fonts/64abcd55-d012-4bb5-a09d-a1e448f2b4b0.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_69 Cm Mediu";
  src: url("Fonts/d630513e-94d0-4fed-9497-bc762577c07f.woff2") format("woff2"),
    url("Fonts/b9351fa0-4d79-45f7-9236-28323476e99f.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_79 Comp Bol";
  src: url("Fonts/58583898-0494-4579-bafb-d504aa8cc480.woff2") format("woff2"),
    url("Fonts/b2053850-e2c0-44fa-a8af-8b299aa6fdb0.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_89 Cm Heavy";
  src: url("Fonts/37affb68-d2dd-460e-8248-b699baced865.woff2") format("woff2"),
    url("Fonts/675d0ef2-6dea-42ca-80fd-175b687eac14.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica W05_99 Cm Black";
  src: url("Fonts/01b2cbed-29f5-498d-ad87-e567fe871fc1.woff2") format("woff2"),
    url("Fonts/708ab8e2-2120-4b70-9c16-0fc203f1ec9c.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_23UltLtE";
  src: url("Fonts/7efec9b7-1374-4adc-8d45-514361fd48b8.woff2") format("woff2"),
    url("Fonts/06b4e4fc-e732-41a7-a362-be9f88aadc89.woff") format("woff");
}
@font-face {
  font-family: "Helvetica NeueLTW0523UltLtExObl";
  src: url("Fonts/7494fa52-ef60-4526-ae97-6a79476f65d1.woff2") format("woff2"),
    url("Fonts/a97edc7d-e8eb-4e13-bccf-2762d3edad44.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_33 Th Ex";
  src: url("Fonts/2f270d4e-e43c-457e-ba8a-0dd33bea037a.woff2") format("woff2"),
    url("Fonts/4595f8b8-db8c-4c01-a7a4-9bb031810849.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_33ThExObl";
  src: url("Fonts/cdf91fc1-04f6-4402-b94c-e943abfa7dea.woff2") format("woff2"),
    url("Fonts/36b66102-f03a-4210-bbee-1c610abba3e3.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_43 Lt Ex";
  src: url("Fonts/86578ae8-4abb-42d0-8784-375d07db941f.woff2") format("woff2"),
    url("Fonts/18b6ae6b-f0cf-422b-bf3f-35be98be3c19.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_43LtExOb";
  src: url("Fonts/c12fc731-15eb-44eb-94a1-fe233d5665b9.woff2") format("woff2"),
    url("Fonts/fde07ba4-9a82-44e0-8f27-969ec84addaa.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_53 Ext";
  src: url("Fonts/0504481e-774c-4e57-a055-c94bb5303380.woff2") format("woff2"),
    url("Fonts/bf166391-2bf9-47fd-8382-193fffb422c4.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_53 Ex Ob";
  src: url("Fonts/d829f114-8f21-4a35-a303-5fc44e646b98.woff2") format("woff2"),
    url("Fonts/12be0a3b-503f-4ef3-a94b-0fcce636cd9c.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_63 Md Ex";
  src: url("Fonts/5069b541-02d6-4f13-9ec2-07e07e9d683f.woff2") format("woff2"),
    url("Fonts/cef4a236-7e60-42cd-997c-99487ff6bb0a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_63MdExOb";
  src: url("Fonts/bdac6a25-6e2e-444a-bf57-324e1c75aebe.woff2") format("woff2"),
    url("Fonts/c92fa14b-1125-4b35-af82-e201823dd789.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_73 Bd Ex";
  src: url("Fonts/3e37e0b1-8062-42c6-8664-4ea78aa2de25.woff2") format("woff2"),
    url("Fonts/a25e22f2-80cf-4f21-a365-b77c87a3edf8.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_73BdExObl";
  src: url("Fonts/a9a0e1ca-8be8-4441-8165-dfca8a447fe9.woff2") format("woff2"),
    url("Fonts/23ffc3f6-e1a7-4b01-b5a8-af27dcd7921c.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_83 Hv Ex";
  src: url("Fonts/37cade8a-4499-42b4-b79f-99143dd3a764.woff2") format("woff2"),
    url("Fonts/6823dde3-d344-4bb4-b5a4-add12527140d.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_83HvExObl";
  src: url("Fonts/1250e9a0-b13e-46b1-9c3f-60e487e7c715.woff2") format("woff2"),
    url("Fonts/9eee9642-ade0-42ba-8906-a13a54c2422a.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_93 Blk E";
  src: url("Fonts/275e1e1e-08b1-4fe8-952b-5484d602fa4e.woff2") format("woff2"),
    url("Fonts/4996b52c-3eb3-43f9-b701-452d8179a1de.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W0593BlkExObl";
  src: url("Fonts/6f623488-6c71-4b67-be62-9a2b09c2f56a.woff2") format("woff2"),
    url("Fonts/6527f829-2c75-49cb-b90f-a7c3f19366f6.woff") format("woff");
}
